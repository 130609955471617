import React from "react";
import Layout from "../components/Layout";
import SVGLogo from '../assets/svg/department-of-treachery.svg';
import Typewriter from 'typewriter-effect';
import {Link} from "gatsby";

const IndexPage = () => {
    return (
        <Layout>
            <div className={"c-login--container o-font--primary"}>
                <div className={"c-login--logo"}>
                    <SVGLogo />
                </div>
                <div className={"c-login--box"}>
                    <input type={"text"} className={"c-login--input o-color--secondary"} placeholder={"Username"}/>
                    <input type={"password"} className={"c-login--input o-color--secondary"} placeholder={"Password"}/>
                    <Link to={"/desktop"} className={"c-login--button o-bg--primary o-color--white o-font--weight-bold"}>
                        Login
                    </Link>
                </div>

                <div className={"c-login--terminal o-color--off-white"}>
                    <Typewriter
                        options={{
                            delay: 15
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .pauseFor(400)
                                .typeString('WARNING!')
                                .pauseFor(1000)
                                .typeString(' You are accessing your personal case on the International blockchain network.')
                                .pauseFor(800)
                                .typeString(' Please be aware that the imagery and footage found here is yours and yours only.')
                                .pauseFor(800)
                                .typeString(' It is your case.')
                                .pauseFor(400)
                                .typeString(' Do with it as you please.')
                                .pauseFor(400)
                                .typeString("<br /><br/>")
                                .typeString('WARNING!')
                                .pauseFor(1000)
                                .typeString(' The use of publicly accessible computers (e.g. libraries, airports, cafes, hotels, etc.) to access the Virtual Academy is unauthorized.')
                                .pauseFor(400)
                                .typeString(' This type of usage may result in the involuntary dissemination of information to unauthorized entities.')
                                .pauseFor(400)
                                .typeString(' Data may be left on this computer resulting in the next person using this computer the ability to view your data. ')
                                .start();
                        }}
                    />
                </div>

            </div>
        </Layout>
    )
}

export default IndexPage;